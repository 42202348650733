import getTokensFromStorage from '../Auth/AuthToken';

let header = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const { accessToken } = getTokensFromStorage();
if (accessToken) {
  let accessTokenData = {
    Authorization: `Bearer ${accessToken}`,
  };
  header = { ...header, ...accessTokenData };
}

const handleApiError = (error) => {
  let errorResponse;
  if (error.response && error.response.data) {
    errorResponse = JSON.stringify(error.response.data);
  } else if (error.request) {
    errorResponse =
      error.request.message || error.request.statusText || error.message;
  } else {
    errorResponse = error.message;
  }
  if (!errorResponse.match('aborted')) {
    throw new Error(errorResponse);
  }
};

export const callApi = async (endpoint, method, data, headers) => {
  try {
    header = { ...headers, ...header };
    const url = process.env.APP_BASE_URL + endpoint;
    const response = await fetch(url, {
      method,
      body: JSON.stringify(data),
      headers: header,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

export default callApi;
