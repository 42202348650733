/**
 * Define the Route List items
 */
import React from 'react';
import NoPermission from '../Modules/NoPermission/NoPermission';
import Splash from '../Modules/Login/Pages/Splash';
import RoadDefects from '../Modules/AiModels/Pages/RoadDefects';
import Warehouse from '../Modules/AiModels/Pages/Warehouse';
import MultipleImageUpload from '../Modules/AiModels/Pages/MultipleImageUpload';
import Welcome from '../Modules/Core/Welcome/Welcome';
import FaceRecognition from "../Modules/AiModels/Pages/FaceRecognition";
import ActivityDetection from "../Modules/AiModels/Pages/ActivityDetection";

const RoutesItemsList = [
    {
        text: 'Dashboard',
        icon: 'k-button-icon k-font-icon k-i-home',
        route: '/',
        element: <Welcome/>,
        layouts: 'CardLayout',
        is_drawer_item: 'true',
        isauth: true,
        is_mobile: 'true',
    },
    {
        text: '',
        icon: '',
        route: '/login',
        element: <Splash/>,
        layouts: 'EmptyLayout',
        is_drawer_item: 'false',
        page_title: 'Login',
        isauth: false,
        is_mobile: 'true',
    },
    {
        text: 'Road Defects',
        icon: 'k-button-icon k-font-icon k-i-ungroup',
        route: '/roaddefects',
        element: <RoadDefects/>,
        layouts: 'CardLayout',
        is_drawer_item: 'true',
        page_title: 'Pothole Model',
        isauth: true,
        is_mobile: 'true',
    },
    {
        text: 'Warehouse',
        icon: 'k-button-icon k-font-icon k-i-thumbnails-left',
        route: '/warehouse',
        element: <Warehouse/>,
        layouts: 'CardLayout',
        is_drawer_item: 'true',
        page_title: 'Warehouse Model',
        isauth: true,
        is_mobile: 'true',
    },
    {
        text: 'Face Recognition',
        icon: 'k-button-icon k-font-icon k-i-user',
        route: '/face-recognition',
        element: <FaceRecognition/>,
        layouts: 'CardLayout',
        is_drawer_item: 'true',
        page_title: 'Face Recognition',
        isauth: true,
        is_mobile: 'true',
    },
    {
        text: 'Upload Multiple Images',
        icon: 'k-button-icon k-font-icon k-i-upload',
        route: '/Upload/Multiple',
        element: <MultipleImageUpload/>,
        layouts: 'CardLayout',
        is_drawer_item: 'false',
        page_title: 'Processed Data',
        isauth: true,
        is_mobile: 'true',
    },
    {
        text: 'Activity Detection',
        icon: 'k-button-icon k-font-icon k-i-accessibility',
        route: '/activity-detection',
        element: <ActivityDetection/>,
        layouts: 'CardLayout',
        is_drawer_item: 'true',
        page_title: 'Activity Detection',
        isauth: true,
        is_mobile: 'true',
    },
    {
        text: 'No Permission',
        route: '/no-permission',
        element: <NoPermission/>,
        layouts: 'TileLayout',
        is_drawer_item: 'false',
        is_mobile: 'false',
    },
];

const RoutesItems = RoutesItemsList;

export default RoutesItems;
